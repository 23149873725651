export const API_MANEK = 'https://manek.rotendaokab.go.id/api/';
//'http://localhost:4000/api/';

export const PERANGKAT_DAERAH = API_MANEK + 'pd/';
export const ANALISIS_JABATAN = API_MANEK + 'anjab/';

export const EFEKTIFITAS = API_MANEK + 'efektifitas/';

export const ADMINS_LOGIN = API_MANEK + 'admin/login';
export const ADMINS_LOGOUT = API_MANEK + 'admin/logout';
export const ADMINS_TOKEN = API_MANEK + 'admin/token';
export const POST_ANJAB = API_MANEK + 'post/anjab/';
export const GET_ANJAB = API_MANEK + 'progress/anjab/';

export const ANALISIS_BEBAN_KERJA = API_MANEK + 'abk/';
export const POST_ABK = API_MANEK + 'post/abk/';
export const GET_ABK = API_MANEK + 'progress/abk/';

export const USERS = API_MANEK + 'users/';
export const LOG_APP = API_MANEK + 'logapp/';
export const GET_NOTIF = API_MANEK + 'notifikasi/';
export const GET_AKTIVITY = API_MANEK + 'useraktivity/';

export const API_TIMEOUT = 120000;
