/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert';

// reactstrap components
import {
  Alert,
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import { API_TIMEOUT } from 'utils';
import { PERANGKAT_DAERAH } from 'utils';
import axios from 'axios';

function ABK() {
  const notificationAlertRef = React.useRef(null);

  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    ambilPerangkatDaerah();
  }, []);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: 'get',
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const notify = (place) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = 'primary';
        break;
      case 2:
        type = 'success';
        break;
      case 3:
        type = 'danger';
        break;
      case 4:
        type = 'warning';
        break;
      case 5:
        type = 'info';
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Welcome to <b>Black Dashboard React</b> - a beautiful freebie for
            every web developer.
          </div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className='content'>
        <div className='react-notification-alert-container'>
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md='6'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Analisis Beban Kerja</CardTitle>
              </CardHeader>
              <CardBody>
                <Alert color='info'>
                  <span>Perangkat Daerah</span>
                </Alert>

                {tabelPerangkatDaerah &&
                  tabelPerangkatDaerah.map((item) => (
                    <UncontrolledAlert
                      className='alert-with-icon'
                      color='info'
                      style={{ cursor: 'pointer' }}
                    >
                      <span
                        className='tim-icons icon-single-02'
                        data-notify='icon'
                      />
                      <span data-notify='message'>{item.nama}</span>
                    </UncontrolledAlert>
                  ))}
              </CardBody>
            </Card>
          </Col>
          <Col md='6'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Beban Kerja</CardTitle>
              </CardHeader>
              <CardBody>
                <UncontrolledAlert color='primary'>
                  <span>
                    <b>Primary - </b>
                    This is a regular notification made with ".alert-primary"
                  </span>
                </UncontrolledAlert>
                <UncontrolledAlert color='info'>
                  <span>
                    <b>Info - </b>
                    This is a regular notification made with ".alert-info"
                  </span>
                </UncontrolledAlert>
                <UncontrolledAlert color='success'>
                  <span>
                    <b>Success - </b>
                    This is a regular notification made with ".alert-success"
                  </span>
                </UncontrolledAlert>
                <UncontrolledAlert color='warning'>
                  <span>
                    <b>Warning - </b>
                    This is a regular notification made with ".alert-warning"
                  </span>
                </UncontrolledAlert>
                <UncontrolledAlert color='danger'>
                  <span>
                    <b>Danger - </b>
                    This is a regular notification made with ".alert-danger"
                  </span>
                </UncontrolledAlert>
              </CardBody>
            </Card>
          </Col>
          <Col md='12'>
            <Card>
              <CardBody>
                <div className='places-buttons'>
                  <Row>
                    <Col className='ml-auto mr-auto text-center' md='6'>
                      <CardTitle tag='h4'>
                        Notifications Places
                        <p className='category'>Click to view notifications</p>
                      </CardTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='ml-auto mr-auto' lg='8'>
                      <Row>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('tl')}
                          >
                            Top Left
                          </Button>
                        </Col>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('tc')}
                          >
                            Top Center
                          </Button>
                        </Col>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('tr')}
                          >
                            Top Right
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='ml-auto mr-auto' lg='8'>
                      <Row>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('bl')}
                          >
                            Bottom Left
                          </Button>
                        </Col>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('bc')}
                          >
                            Bottom Center
                          </Button>
                        </Col>
                        <Col md='4'>
                          <Button
                            block
                            color='primary'
                            onClick={() => notify('br')}
                          >
                            Bottom Right
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ABK;
