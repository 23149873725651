/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/Dashboard.js';

import Map from 'views/Map.js';
import Anjab from 'views/Anjab';

import Rtl from 'views/Rtl.js';

import Typography from 'views/Typography.js';
import UserProfile from 'views/UserProfile.js';
import ABK from 'views/ABK';

import PetaJabatan from 'views/PetaJabatan';
import Sakip from 'views/Sakip';
import Anjab_Tab from 'views/Anjab_Tab';
import ABK_Ok from 'views/ABK_Ok';
import ABK_Tab from 'views/ABK_Tab';
import UsersManek from 'views/UsersManek';

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/admin',
    sidebar: true,
    user: true,
  },
  {
    path: '/anjab',
    name: 'Analisis Jabatan',
    rtlName: 'الرموز',
    icon: 'tim-icons icon-atom',
    component: Anjab_Tab,
    layout: '/admin',
    sidebar: true,
    user: true,
  },

  {
    path: '/dokumenanjab',
    name: 'Dokumen Analisis Jabatan',
    rtlName: 'الرموز',
    icon: 'tim-icons icon-atom',
    component: Anjab,
    layout: '/admin',
    sidebar: false,
    user: true,
  },

  {
    path: '/abk',
    name: 'Analisis Beban Kerja',
    rtlName: 'إخطارات',
    icon: 'tim-icons icon-bell-55',
    component: ABK_Tab,
    layout: '/admin',
    sidebar: true,
    user: true,
  },
  {
    path: '/dokumenabk',
    name: 'Dokumen Analisis Beban Kerja',
    rtlName: 'إخطارات',
    icon: 'tim-icons icon-bell-55',
    component: ABK_Ok,
    layout: '/admin',
    sidebar: false,
    user: true,
  },

  {
    path: '/petajabatan',
    name: 'Peta Jabatan',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-puzzle-10',
    component: PetaJabatan,
    layout: '/admin',
    sidebar: true,
    user: true,
  },

  {
    path: '/users-manek',
    name: 'Users Manek',
    rtlName: 'ملف تفي للمستخدم',
    icon: 'tim-icons icon-single-02',
    component: UsersManek,
    layout: '/admin',
    sidebar: true,
    user: false,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    rtlName: 'ملف تفي للمستخدم',
    icon: 'tim-icons icon-single-02',
    component: UserProfile,
    layout: '/admin',
    sidebar: true,
    user: true,
  },
];
export default routes;
