/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { PERANGKAT_DAERAH, API_TIMEOUT } from 'utils';

function Sakip() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState('');

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  useEffect(() => {
    ambilPerangkatDaerah();
  }, []);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: 'get',
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Perangkat Daerah</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className='tablesorter' responsive>
                  <thead className='text-primary'>
                    <tr>
                      <th>No</th>
                      <th>Perangkat Daerah</th>
                      <th>Status</th>
                      <th>Dokumen</th>
                      <th className='text-center'>Finalisasi</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>
                          <td>On Progress</td>
                          <td
                            className='text-center'
                            onClick={() => setModalOpen2(true)}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className='tim-icons icon-single-copy-04' />
                          </td>
                          <td className='text-center'>
                            <i className='tim-icons icon-check-2' />{' '}
                          </td>
                          <td className='text-center'>
                            <Button
                              color='primary'
                              size='sm'
                              onClick={() => setModalOpen(true)}
                            >
                              edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName='p-0'
          modalClassName='modal-slide-in event-sidebar'
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className='headline-2'>{'Upload Dokumen'}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label lg='3' className='text-md-right mt-3'>
                File Analisis Jabatan (Pdf)
              </Label>
              <Col lg='9'>
                <div>
                  <Input
                    id='upload'
                    type='file'
                    accept='application/pdf'
                    onChange={onChangeDok}
                  />
                  <label
                    id='upload-label'
                    htmlFor='upload'
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : 'Pilih file'}
                  </label>
                  <div className='input-group-append'>
                    <label
                      htmlFor='upload'
                      className='btn btn-light m-0 rounded-pill px-4'
                    >
                      <i className='fa fa-cloud-upload mr-2 text-muted'></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className='btn-rounded'
                size='sm'
                color='secondary-red'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button className='btn-rounded' color='primary' size='sm'>
                {' '}
                {'Simpan'}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size='md'
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName='p-0'
          modalClassName='modal-slide-in event-sidebar'
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className='headline-2'>{'Upload Dokumen Final'}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label lg='3' className='text-md-right mt-3'>
                File Analisis Jabatan (word)
              </Label>
              <Col lg='9'>
                <div>
                  <Input
                    id='upload'
                    type='file'
                    accept='.docx'
                    onChange={onChangeDok}
                  />
                  <label
                    id='upload-label'
                    htmlFor='upload'
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : 'Pilih file'}
                  </label>
                  <div className='input-group-append'>
                    <label
                      htmlFor='upload'
                      className='btn btn-light m-0 rounded-pill px-4'
                    >
                      <i className='fa fa-cloud-upload mr-2 text-muted'></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className='btn-rounded'
                size='sm'
                color='secondary-red'
                onClick={() => setModalOpen2(false)}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button className='btn-rounded' color='primary' size='sm'>
                {' '}
                {'Simpan'}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Sakip;
