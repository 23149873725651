/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { Document, Page } from 'react-pdf';

import { PERANGKAT_DAERAH, API_TIMEOUT } from 'utils';
import { toProperCase } from 'utils/func';
import { useHistory } from 'react-router-dom';
import { logApp } from 'utils/func';

function Anjab() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const pdfURL = 'https://www.dpr.go.id/dokjdih/document/uu/441.pdf';

  const [currentUser, setCurrentUser] = useState('');

  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, 'Dok Anjab');
        setCurrentUser(user);
      } else {
        history.push('/login');
      }
    }
  }, []);

  useEffect(() => {
    ambilPerangkatDaerah();
  }, []);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: 'get',
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5 className='title'>Dokumen Analisis Jabatan</h5>
                  <p className='category'>Daftar Perangkat Daerah </p>
                </div>
                <div>
                  <Input type='select' style={{ color: 'white', width: 100 }}>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </Input>
                </div>
              </CardHeader>
              <CardBody className='all-icons'>
                <Row>
                  {tabelPerangkatDaerah &&
                    tabelPerangkatDaerah.map((item) => (
                      <Col
                        className='font-icon-list col-xs-6 col-xs-6'
                        lg='2'
                        md='3'
                        sm='4'
                      >
                        <div
                          className='font-icon-detail'
                          style={{ cursor: 'pointer' }}
                          onClick={() => setModalOpen(true)}
                        >
                          <i className='tim-icons icon-single-copy-04' />
                          <p>{toProperCase(item.nama)}</p>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Anjab;
