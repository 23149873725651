import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { Document, Page } from 'react-pdf';

import { PERANGKAT_DAERAH, API_TIMEOUT } from 'utils';
import { toProperCase } from 'utils/func';
import { logApp } from 'utils/func';
import { useHistory } from 'react-router-dom';

function ABK_Ok() {
  const domain = 'https://simobat.rotendaokab.go.id/dokumen/';
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState('');

  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, 'Dok ABK');
        setCurrentUser(user);
      } else {
        history.push('/login');
      }
    }
  }, []);

  useEffect(() => {
    ambilPerangkatDaerah();
  }, []);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: 'get',
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5 className='title'>Dokumen Analisis Beban Kerja</h5>
                  <p className='category'>Daftar Perangkat Daerah </p>
                </div>
                <div>
                  <Input type='select' style={{ color: 'white', width: 100 }}>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </Input>
                </div>
              </CardHeader>
              <CardBody className='all-icons'>
                <Row>
                  {tabelPerangkatDaerah &&
                    tabelPerangkatDaerah.map((item) => (
                      <Col
                        className='font-icon-list col-xs-6 col-xs-6'
                        lg='2'
                        md='3'
                        sm='4'
                      >
                        <div
                          className='font-icon-detail'
                          style={{ cursor: 'pointer' }}
                          onClick={() => openPDF(domain + item.dokumen)}
                        >
                          <i className='tim-icons icon-single-copy-04' />
                          <p>{toProperCase(item.nama)}</p>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size='lg'
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          className='sidebar-lg'
          contentClassName='p-0'
          modalClassName='modal-slide-in event-sidebar'
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className='headline-2'>{'Dokumen Analisis Jabatan'}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <div>
                <h1>Viewer PDF</h1>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className='btn-rounded'
                size='sm'
                color='secondary-red'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button className='btn-rounded' color='primary' size='sm'>
                {' '}
                {'Simpan'}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ABK_Ok;
